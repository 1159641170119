import "./Landing.css";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Float, ScrollControls, useScroll, Stars } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { DoubleSide, MeshStandardMaterial } from "three";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Landing() {
  const canvasRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const logoScale = () => {
    if (window.innerWidth < 1400) {
      return (window.innerWidth / 1400) ** (1 / 1.7);
    } else {
      return 1;
    }
  };

  const [scale, setScale] = useState(logoScale());

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScale(logoScale());
    });
  }, []);

  const material = new MeshStandardMaterial({
    color: "coral",
    opacity: 0.77,
    side: DoubleSide,
    transparent: true,
  });

  function Title() {
    let file = "plastiic-logo.stl";
    let obj = useLoader(STLLoader, file);
    obj.center();

    const groupRef = useRef();
    const data = useScroll();
    const followIntensity = 0.5;
    useFrame((state, delta) => {
      groupRef.current.rotation.x = -state.pointer.y * followIntensity;

      if (!data.visible(1 / 4, 1 / 2)) {
        groupRef.current.rotation.y = state.pointer.x * followIntensity;
      }

      if (data.visible(1 / 4, 1 / 2)) {
        groupRef.current.rotation.y = (data.offset - 1 / 4) * 4 * Math.PI;
      } else if (data.visible(3 / 4, 1 / 4)) {
        setLoading(true);
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 1500)).then(() => {
            navigate("/home");
          });
        })();
      }
    });
    return (
      <group ref={groupRef}>
        <mesh
          geometry={obj}
          material={material}
          position={[0, 0, 0]}
          rotation={[2 * Math.PI - Math.PI / 2, 0, 0]}
          // scale={0.001 * 7.5}
          scale={0.001 * 7.5 * scale}
        />
      </group>
    );
  }

  return (
    <div id="landing-page">
      <Canvas ref={canvasRef}>
        <directionalLight intensity={2.45} position={[0, 2, 2]} />
        <Stars
          radius={100}
          depth={50}
          count={5000}
          factor={4.3}
          saturation={0}
          fade
          speed={2}
        />
        <Float speed={1.5} rotationIntensity={0} floatingRange={[-0.22, 0.22]}>
          <ScrollControls style={{ scrollbarWidth: "none" }}>
            <Title />
          </ScrollControls>
        </Float>
      </Canvas>
      <h1>scroll down ▼</h1>
      {loading && <h1 id="landing-redirecting">Welcome! Redirecting...</h1>}
    </div>
  );
}
export default Landing;
