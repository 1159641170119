import "./Nav.css";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav id="nav-grid">
      <div id="nav-center">
        <Link id="nav-title" to="/home">
          plastiic
        </Link>
        <div>
          <Link className="nav-link" to="/estimate">
            Estimate
          </Link>
          <Link className="nav-link" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
