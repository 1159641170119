import "./NoPage.css";
import "./Page.css";

function NoPage() {
  return (
    <div className="page-grid">
      <div className="page-center" id="nopage-center">
        <h1>404 Page Not Found</h1>
      </div>
    </div>
  );
}

export default NoPage;
