import "./Estimate.css";
import "./Page.css";
import Viewer from "../Components/Viewer";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

function Estimate() {
  const [file, setFile] = useState(null);
  const [cost, setCost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);

  const handleFileChange = async (e) => {
    e.preventDefault();
    setError(null);
    setCost(null);
    setLoading(true);
    setDisabled(true);

    if (!e.target[0].files[0]) {
      setLoading(false);
      return;
    }

    if (e.target[0].files[0].name.slice(-3) !== "stl") {
      setError("Invalid file type");
      setLoading(false);
      return;
    }

    let formData = new FormData();
    formData.append("file", e.target[0].files[0]);
    await axios
      .post("https://plastiic-ca-server.vercel.app/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const bufArr = Uint8Array.from(atob(res.data.b64), (c) =>
          c.charCodeAt(0)
        ).buffer;
        setFile(bufArr);
        setCost(res.data.cost);
        setLoading(false);
      })
      .catch((err) => {
        setError("An error has occured. Please try again.");
        setLoading(false);
      });
  };

  return (
    <div className="page-grid">
      <div className="page-center" id="estimate-center">
        <div id="estimate-frame">
          <div id="estimate-details">
            <h1>Cost Estimator</h1>
            <h2>
              Upload your 3D model in an STL format to get an instant estimate!
            </h2>
            <h2>
              Cost estimates are based on the amount of filament used, the
              amount of time required to print, and operation fees.
            </h2>
            <h2>
              Batch printing is available at a reduced rate. Please contact us
              to learn more.
            </h2>
            <h3>Currently, we only print in PLA using FDM printers.</h3>
            <h3>
              If you are ready to move forward with us, please fill out the{" "}
              <Link id="estimate-contactus" to={"/contact"}>
                contact form
              </Link>{" "}
              and we can begin the process.
            </h3>
          </div>
          <div id="estimate-viewer">
            <div>
              <Viewer printerDimensions={[1.22, 1.25, 1.22]} file={file} />
              {loading && (
                <div id="estimate-loading">
                  <BeatLoader
                    color="rgba(255, 240, 208, 0.8)"
                    size={50}
                    speedMultiplier={0.2}
                  />
                </div>
              )}
            </div>

            <form encType="multipart/form-data" onSubmit={handleFileChange}>
              <label htmlFor="file-upload">Select an STL file:</label>
              <input
                id="file-upload"
                type="file"
                name="file"
                onInput={() => {
                  setDisabled(false);
                }}
              />
              <div>
                <input
                  disabled={disabled || loading}
                  type="submit"
                  value="Get Estimate"
                />
                {loading && <h3>Processing File...</h3>}
                {error && <h3>{error}</h3>}
                {cost && (
                  <h3>
                    Estimated Cost: <span>${cost}</span>
                  </h3>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Estimate;
