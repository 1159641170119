import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./Utilities/ScrollToTop";
import Landing from "./Pages/Landing";
import Nav from "./Components/Nav";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Estimate from "./Pages/Estimate";
import Contact from "./Pages/Contact";
import NoPage from "./Pages/NoPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {
      <>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/"
              element={
                <>
                  <Nav />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="home" element={<Home />} />
              <Route path="estimate" element={<Estimate />} />
              <Route path="contact" element={<Contact />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    }
  </React.StrictMode>
);
