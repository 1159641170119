import "./Viewer.css";
import { Canvas, useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import {
  DirectionalLight,
  DoubleSide,
  BoxGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  MeshStandardMaterial,
} from "three";
import { OrbitControls, GizmoHelper, GizmoViewcube } from "@react-three/drei";

/*
creates the model primitive and assigns material
*/
const Model = ({ object, position, scale, color }) => {
  const loader = new STLLoader();

  let obj = useLoader(STLLoader, "demo.stl");

  if (object) {
    obj = loader.parse(object);
  }

  obj.center();
  const mid = (obj.boundingBox.max.z - obj.boundingBox.min.z) / 2;
  obj.translate(0, 0, mid);

  const material = new MeshStandardMaterial({
    color: color,
    transparent: true,
    opacity: 0.77,
    side: DoubleSide,
  });

  return (
    <mesh
      geometry={obj}
      material={material}
      position={position}
      rotation={[2 * Math.PI - Math.PI / 2, 0, 0]}
      scale={0.001 * scale}
    />
  );
};

/*
creates the printer mesh
*/
const Printer = ({ position, dimensions }) => {
  const boxGeometry = new BoxGeometry(...dimensions);
  const boxEdges = new EdgesGeometry(boxGeometry);
  const lineMaterial = new LineBasicMaterial({
    color: "rgb(255, 240, 208)",
    opacity: 0.56,
    transparent: true,
  });

  return (
    <group position={position}>
      <mesh>
        <lineSegments geometry={boxEdges} material={lineMaterial} />
      </mesh>

      <mesh
        position={[0, -dimensions[1] / 2, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={dimensions[0]}
      >
        <planeGeometry />
        <meshStandardMaterial
          color={"rgb(255, 240, 208)"}
          opacity={0.56}
          side={DoubleSide}
          transparent
        />
      </mesh>
    </group>
  );
};

/*
creates a light source and adds it to the
camera then adds the camera to the scence
*/
const initializeCanvas = (camera, scene) => {
  const light = new DirectionalLight();
  light.position.set(0, 2, 2);
  light.intensity = 2.3;
  camera.translateZ(-3);
  camera.translateY(0.2);
  camera.translateX(2.3);
  camera.position.multiplyScalar(0.58);
  camera.add(light);
  scene.add(camera);
};

/*
creates a 3D viewer with a model and controls
*/
function Viewer({ printerDimensions, file }) {
  return (
    <>
      <Canvas
        onCreated={({ camera, scene }) => initializeCanvas(camera, scene)}
      >
        {/* Model */}
        <Model
          object={file}
          position={[0, -printerDimensions[1] / 2, 0]}
          color={"coral"}
          scale={5}
        />

        {/* Printer */}
        <Printer position={[0, 0, 0]} dimensions={printerDimensions} />

        {/* Controls */}
        <OrbitControls makeDefault />

        {/* Viewcube */}
        <GizmoHelper>
          <GizmoViewcube color="rgb(255, 240, 208)" />
        </GizmoHelper>
      </Canvas>
    </>
  );
}

export default Viewer;
