import "./Home.css";
import "./Page.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="page-grid">
      <div className="page-center" id="home-center">
        <div id="home-hero">
          <div>
            <div>
              <h1>Want an estimate?</h1>
              <h2>Upload your 3D model now and get an estimate instantly!</h2>
            </div>
            <Link id="home-hero-button" to={"/estimate"}>
              <h1>Yes, I'm interested</h1>
            </Link>
          </div>
        </div>
        <div id="home-testimonies">
          <div className="home-testimony">
            <h1>
              "Plastiic consistently delivers high-quality 3D prints with
              precision and speed. They’ve been essential in bringing my designs
              to life."
            </h1>
            <p>- Geoff C.</p>
          </div>
          <div className="home-testimony">
            <h1>
              "Plastiic is my go-to for 3D printing. Their quality and attention
              to detail are unmatched. Highly recommended!"
            </h1>
            <p>- Jamie S.</p>
          </div>
          <div className="home-testimony">
            <h1>
              "Plastiic exceeded my expectations with their top-notch 3D
              printing services. Reliable, fast, and always accurate."
            </h1>
            <p>- Mitch S.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
