import "./Footer.css";

function Footer() {
  return (
    <footer id="footer-grid">
      <div id="footer-center">
        <p>© 2024 Plastiic</p>
      </div>
    </footer>
  );
}

export default Footer;
