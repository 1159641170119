import "./Contact.css";
import "./Page.css";
import axios from "axios";
import { useState } from "react";

function Contact() {
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post("https://plastiic-ca-server.vercel.app/contact", {
        email: e.target[0].value,
        location: e.target[1].value,
        message: e.target[2].value,
      })
      .then((res) => {
        setMessage("Message sent!");
        e.target.reset();
      })
      .catch((err) => {
        setMessage("Message failed to send.");
      });
  };

  return (
    <div className="page-grid">
      <div className="page-center" id="contact-center">
        <div id="contact-frame">
          <div id="contact-details">
            <h1>Contact Us</h1>
            <h2>Currently serving the Greater Toronto Area.</h2>
            <h2>
              Please tell us all about your project, we like to know as much as
              possible so that we can provide you with great service and even
              better prices.
            </h2>
            <h2>We will get back to you as soon as possible!</h2>
          </div>
          <form
            id="contact-form"
            onChange={() => setMessage(null)}
            onSubmit={handleSubmit}
          >
            <div className="contact-oneline">
              <label>Email:</label>
              <input
                id="contact-email"
                type="email"
                required
                placeholder="example@domain.com"
              />
            </div>
            <div className="contact-oneline">
              <label>Location:</label>
              <input
                id="contact-location"
                type="text"
                required
                autoComplete="false"
                placeholder="City, Province"
              />
            </div>
            <div className="contact-multiline">
              <label>Project Details:</label>
              <textarea
                id="contact-message"
                type="text"
                required
                autoComplete="false"
                placeholder="Tell us about your project..."
              />
            </div>
            <div id="contact-submit">
              <input type="submit" value="Send" />
              {message && <h3>{message}</h3>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
